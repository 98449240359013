import React, { useEffect, useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import useGoogleCalendar from '../../hooks/useGoogleCalendar'
import CalendarRow from '@/components/Calendar/partials/ReusableRow'

export default function Calendar({ options, full }) {
  const [activeSwitcher, setActiveSwitcher] = useState(123)
  const [cols, setCols] = useState([])
  const events = useGoogleCalendar(options.sheet_id, options.sheet_name)
  useEffect(() => {
    if (events.length) {
      setCols(Object.keys(events[0]))
      console.log('cols', cols)
    }
  }, [events])
  return (
    <section className={`calendar-section ${full ? 'full-size' : ''}`}>
      <div className='container'>
        <section className='calendar'>
          <div className='calendar__header'>
            <h2 data-aos='fade-right' className='tablet-title'>
              {options?.title}
            </h2>
          </div>
          <div className='calendar__body' data-aos='fade-up'>
            <div className='calendar__table table'>
              <div
                data-aos='fade-up'
                className={`table__row table__row--header table__row--${cols.length}`}>
                {cols &&
                  cols.map((col, i) => (
                    <div
                      className='table__col table__col--header'
                      key={i}
                      style={{ textTransform: 'capitalize' }}>
                      {col}
                    </div>
                  ))}
              </div>
              <SwitchTransition mode='out-in'>
                <CSSTransition
                  key='12'
                  addEndListener={(node, done) => {
                    node.addEventListener('transitionend', done, false)
                  }}
                  classNames='fade'>
                  <div className='table__body'>
                    {events.map((event, i) => (
                      <CalendarRow key={i} event={event} />
                    ))}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}
