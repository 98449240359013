import React, { useState, useEffect } from 'react'

function useGoogleCalendar(sheetID, sheetName) {
  const [events, setEvents] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${sheetName}?key=AIzaSyD-fyLY5DapIlPayPFoEQB2xteEj2aFyUk`,
        )
        const data = await response.json()
        const keys = data.values[0]
        const values = data.values.slice(1)
        const items = values.map((array) => {
          const object = {}
          keys.forEach((key, i) => (object[key.toLowerCase()] = array[i]))
          return object
        })
        console.log('Hook', items)
        setEvents(items)
      } catch (error) {
        console.log(error.response)
      }
    }
    fetchData()
  }, [sheetID, sheetName])

  return events
}

export default useGoogleCalendar
