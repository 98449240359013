import { window, document, exists } from 'browser-monads'

const ScrollHelper = {
  init() {
    if (exists(window) && exists(document)) {
      const scrollify = require('jquery-scrollify')
      scrollify({
        section: '.full-size',
        scrollSpeed: 800,
        updateHash: false,
      })
    }
  },
  destroy() {
    if (exists(window) && exists(document)) {
      const scrollify = require('jquery-scrollify')
      scrollify.destroy()
    }
  },
  update() {
    if (exists(window) && exists(document)) {
      const scrollify = require('jquery-scrollify')
      scrollify.destroy()
      setTimeout(function() {
        scrollify({
          section: '.full-size',
          scrollSpeed: 1200,
          updateHash: false,
        })
      }, 1200)
    }
  },
}

export default ScrollHelper
