import React, { Component } from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

import Helper from '@/helpers/SiteHelper'

export default class CalendarRow extends Component {
  render() {
    const project = this.props.data.node.frontmatter
    const options = this.props.options

    return (
      <div className={`table__row table__row--${options.columns.length}`}>
        {options.columns &&
          options.columns.map((col) => {
            if (col.key === 'details.when') {
              return (
                <div className='table__col' key={col.key}>
                  <div className='table__col__label'>{col.label}</div>
                  <div className='table__col__val'>
                    {project.calendar.startdate &&
                      project.calendar.startdate.format('ll')}{' '}
                    {project.calendar.enddate &&
                      project.calendar.enddate !==
                        project.calendar.startdate._i &&
                      (!project.calendar.isOngoing
                        ? ` - ${moment(project.calendar.enddate).format('ll')}`
                        : ' - Ongoing')}{' '}
                  </div>
                </div>
              )
            } else if (col.key === 'title' && project.layout !== null) {
              return (
                <Link
                  className='table__col table__col--link'
                  to={this.props.data.node.fields.slug}
                  key={col.key}>
                  <div className='table__col__label'>{col.label}</div>
                  <div className='table__col__val'>
                    {Helper.resolve(col.key, project)}
                  </div>
                </Link>
              )
            } else {
              return (
                <div className='table__col' key={col.key}>
                  <div className='table__col__label'>{col.label}</div>
                  <div className='table__col__val'>
                    {Helper.resolve(col.key, project)}
                  </div>
                </div>
              )
            }
          })}
      </div>
    )
  }
}
