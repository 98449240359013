import React from 'react'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import Helmet from 'react-helmet'
import uniq from 'lodash/uniq'

import Image from '@/components/Image'
import Layout from '@/components/Layout'
import Attribute from '@/components/Attribute'
import SubProject from '@/components/Projects/Subproject'

import ProjectCalendar from '@/components/Calendar/ProjectCalendar'
import ReusableCalendar from '@/components/Calendar/ReusableCalendar'
import AirtableIntegration from '@/components/Airtable/AirtableIntegration'

import SEO from '@/helpers/SEOHelper.js'

export const ProjectTemplate = ({ project, content, projects }) => {
  const details = project.details
  const gallery = project.gallery

  function sortProjectsByIds(projects, ids) {
    let result = []
    ids.forEach((id) => {
      let project = projects.find((item) => item.node.frontmatter.id === id)
      if (project) result.push(project)
    })
    return result
  }

  const calendarSetup = {
    title: 'Project calendar',
    switcher: false,
    search: true,
    columns: [
      {
        label: 'Project',
        key: 'title',
      },
      {
        label: 'Client',
        key: 'details.client',
      },
      {
        label: 'Purpose',
        key: 'details.purpose',
      },
      {
        label: 'Roles',
        key: 'details.roles',
      },
      {
        label: 'When',
        key: 'details.when',
      },
    ],
  }

  let promoted = projects

  const events = projects && project.events ? projects : []

  promoted = uniq([...events, ...promoted])

  const calendarProjects =
    promoted.length > 0
      ? promoted.filter((item) => {
          let show = item.node.frontmatter.calendar
            ? item.node.frontmatter.calendar.show
            : false
          return show
        })
      : []
  return (
    <>
      <section className='ProjectPage'>
        <div className='container'>
          <div className='ProjectPage__hero'>
            <div className='cols cols--15'>
              <div className='col col-50'>
                {project.video?.active ? (
                  <video
                    className='featured-video'
                    src={project.video.url}
                    controls
                    muted
                    loop
                    autoPlay></video>
                ) : (
                  project.thumbnail && (
                    <Image
                      className='project__image mb-32'
                      aos='fade-right'
                      loading='eager'
                      author={project.thumbnail.author}
                      image={project.thumbnail.image}
                    />
                  )
                )}
                <div className='ProjectPage__details'>
                  <h1
                    className='mb-40'
                    data-aos='fade-right'
                    data-aos-delay='50'>
                    {project.title}
                  </h1>
                  <div
                    className='mb-64'
                    data-aos='fade-right'
                    data-aos-delay='100'>
                    {ReactHtmlParser(content)}
                  </div>
                  <div
                    className='attributes'
                    data-aos='fade-right'
                    data-aos-delay='150'>
                    {project &&
                      Object.keys(details).map((key, i) => (
                        <Attribute
                          key={i}
                          name={key}
                          value={details[key]}
                          light={true}></Attribute>
                      ))}
                  </div>
                </div>
              </div>
              <div className='col-50'>
                <div className='ProjectPage__top-gallery'>
                  {gallery &&
                    gallery
                      .slice(0, 3)
                      .map((item, i) => (
                        <Image
                          key={i}
                          className='gallery-item'
                          aos='fade-left'
                          loading='eager'
                          author={item.author}
                          image={item.image}
                        />
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {project.airtable?.link && (
          <AirtableIntegration url={project.airtable} />
        )}

        {project.googleCalendar?.show ? (
          <ReusableCalendar options={project.googleCalendar} />
        ) : calendarProjects.length > 0 ? (
          <ProjectCalendar
            options={calendarSetup}
            projects={calendarProjects}></ProjectCalendar>
        ) : (
          ''
        )}

        <div className='container'>
          <div className='ProjectPage__children'>
            {promoted.length > 0 &&
              promoted.map(({ node: project }) => (
                <SubProject
                  key={project.id}
                  project={project}
                  content={project.excerpt}></SubProject>
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

const ProjectPage = ({ data }) => {
  const { frontmatter: project } = data.project
  const { edges: projects } = data.projects
  const content = data.project.html
  const seo = project.seo

  const description = seo ? seo.description : ''
  const title = seo ? seo.title : project.title

  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(description)} />
        <meta property='og:title' content={SEO.title(title)} />
        <meta
          property='og:description'
          content={SEO.description(description)}
        />
        <title>{SEO.title(title)}</title>
        {project.secret && <meta name='robots' content='noindex,nofollow' />}
      </Helmet>
      <ProjectTemplate
        project={project}
        projects={projects}
        content={content}
      />
    </Layout>
  )
}

export default ProjectPage

export const projectPageQuery = graphql`
  query ProjectPage($id: String!, $projects: [String!], $isSecret: Boolean!) {
    project: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        id
        title
        secret
        airtable {
          link
          view
          sort {
            field
            direction
          }
        }
        googleCalendar {
          title
          sheet_id
          sheet_name
          show
        }
        video {
          active
          url
        }
        thumbnail {
          author
          image {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 45
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        details {
          client
          purpose
          type
          roles
          size
          when
          where
          established
          regularity
          initiatives
          website
        }
        gallery {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 45
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          author
        }
        events
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: {
        frontmatter: {
          id: { in: $projects }
          type: { eq: "project" }
          secret: { eq: $isSecret }
        }
      }
      sort: { fields: frontmatter___calendar___startdate, order: DESC }
    ) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 120, format: HTML)
          fields {
            slug
          }
          frontmatter {
            id
            title
            layout
            parent
            video {
              active
              url
            }
            thumbnail {
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 1000
                    quality: 45
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              author
            }
            details {
              client
              purpose
              type
              roles
              size
              when
              where
              established
              regularity
              initiatives
              website
            }
            calendar {
              show
              startdate
              enddate
              isOngoing
            }
          }
        }
      }
    }
  }
`
