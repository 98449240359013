import React, { Component } from 'react'
import Fuse from 'fuse.js'
import moment from 'moment'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import CalendarRow from '@/components/Calendar/partials/ProjectRow'

import Scroll from '@/helpers/ScrollHelper'

export default class ProjectCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSwitcher: 'upcoming',
      fuse: [],
      projects: this.props.projects,
      query: '',
      events: false,
    }
    this.handleSwitchClick = this.handleSwitchClick.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.prepareEvents = this.prepareEvents.bind(this)
  }

  componentDidMount() {
    const options = {
      keys: ['node.frontmatter.title', 'node.frontmatter.details.client'],
    }
    this.setState({
      fuse: new Fuse(this.props.projects, options),
    })

    this.prepareEvents(this.props.projects)
  }

  handleSwitchClick(val) {
    Scroll.update()
    this.setState({
      activeSwitcher: val,
    })
  }

  prepareEvents(events) {
    events.forEach((event) => {
      event.node.frontmatter.calendar.startdate = moment(
        event.node.frontmatter.calendar.startdate,
        'YYYYMMDD',
      )
    })
    events.sort(
      (a, b) =>
        b.node.frontmatter.calendar.startdate.format('YYYYMMDD') -
        a.node.frontmatter.calendar.startdate.format('YYYYMMDD'),
    )

    let eventsList = events

    const options = {
      keys: ['node.frontmatter.title', 'details', 'role'],
    }

    this.setState({
      events: eventsList,
      fuse: new Fuse(eventsList, options),
    })
  }

  handleInput(e) {
    let searchInput = e.target.value
    this.setState((state) => {
      return {
        query: searchInput,
        events:
          searchInput.length > 0
            ? state.fuse.search(searchInput).map((i) => i.item)
            : this.props.projects,
      }
    })
  }

  render() {
    const options = this.props.options
    return (
      <section
        className={`calendar-section ${this.props.full ? 'full-size' : ''}`}>
        <div className='container'>
          <section className='calendar'>
            <div className='calendar__header'>
              <h2 data-aos='fade-right' className='tablet-title'>
                {options.title}
              </h2>
              {options.search && (
                <input
                  type='text'
                  className='search-input'
                  data-aos='fade-left'
                  placeholder='Search..'
                  value={this.state.query}
                  onChange={this.handleInput}
                />
              )}
            </div>
            <div className='calendar__body' data-aos='fade-up'>
              <div className='calendar__table table'>
                <div
                  data-aos='fade-up'
                  className={`table__row table__row--header table__row--${options.columns.length}`}>
                  {options.columns &&
                    options.columns.map((col, i) => (
                      <div className='table__col table__col--header' key={i}>
                        {col.label}
                      </div>
                    ))}
                </div>{' '}
                <SwitchTransition mode='out-in'>
                  <CSSTransition
                    key={this.state.activeSwitcher}
                    addEndListener={(node, done) => {
                      node.addEventListener('transitionend', done, false)
                    }}
                    classNames='fade'>
                    <div className='table__body'>
                      {this.state.events &&
                        this.state.events.map((project, index) => (
                          <CalendarRow
                            key={project.id || index}
                            data={project}
                            options={options}
                            index={index}></CalendarRow>
                        ))}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </section>
        </div>
      </section>
    )
  }
}
